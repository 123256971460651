<script setup lang="ts">
import { darkModeKey, styleKey } from "@/configs/config";
import { mdiForwardburger, mdiBackburger, mdiMenu } from "@mdi/js";
import { ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import menuAside from "@/configs/menuAside";
import menuNavBar from "@/configs/menuNavBar";
import { useMainStore } from "@/stores/main";
import { useStyleStore } from "@/stores/style";
import { useAuthStore } from "@/stores/auth";
useMainStore().setUser({
  name: "John Doe",
  email: "john@example.com",
  avatar:
    "https://source.boringavatars.com/beam/120/kabbima?colors=264653,2a9d8f,e9c46a,f4a261,e76f51",
});

const layoutAsidePadding = "xl:pl-60";

const styleStore = useStyleStore();
const authStore = useAuthStore();

const router = useRouter();

const isAsideMobileExpanded = ref(false);
const isAsideLgActive = ref(false);

const currentStyle =
  typeof localStorage !== "undefined" && localStorage[styleKey] ? localStorage[styleKey] : "basic";
styleStore.setStyle(currentStyle);

// set default theme to light mode
styleStore.setDarkMode(false);

onBeforeMount(() => {
  isAsideMobileExpanded.value = false;
  isAsideLgActive.value = false;
});

const menuClick = (event: MouseEvent, item: any) => {
  if (item.isToggleLightDark) {
    styleStore.setDarkMode();
  }

  if (item.isLogout) {
    authStore.logout();
    router.replace("/login");
  }
};
const { scrollEl } = useScrollMain();
</script>

<template>
  <div
    class="scroll-element"
    ref="scrollEl"
    :class="{
      dark: styleStore.darkMode,
      'overflow-hidden lg:overflow-visible': isAsideMobileExpanded,
    }"
  >
    <div
      :class="[layoutAsidePadding, { 'ml-60 lg:ml-0': isAsideMobileExpanded }]"
      class="pt-14 min-h-screen w-screen transition-position lg:w-auto bg-gray-50 dark:bg-slate-800 dark:text-slate-100"
    >
      <NavBar
        :menu="menuNavBar"
        :class="[layoutAsidePadding, { 'ml-60 lg:ml-0': isAsideMobileExpanded }]"
        @menu-click="menuClick"
      >
        <NavBarItemPlain
          display="flex lg:hidden"
          @click.prevent="isAsideMobileExpanded = !isAsideMobileExpanded"
        >
          <BaseIcon
            :path="isAsideMobileExpanded ? mdiBackburger : mdiForwardburger"
            size="24"
          />
        </NavBarItemPlain>
        <NavBarItemPlain
          display="hidden lg:flex xl:hidden"
          @click.prevent="isAsideLgActive = true"
        >
          <BaseIcon
            :path="mdiMenu"
            size="24"
          />
        </NavBarItemPlain>
      </NavBar>
      <AsideMenu
        :is-aside-mobile-expanded="isAsideMobileExpanded"
        :is-aside-lg-active="isAsideLgActive"
        :menu="menuAside"
        @menu-click="menuClick"
        @aside-lg-close-click="isAsideLgActive = false"
      />
      <slot />
    </div>
    <div id="portal-target"></div>
  </div>
</template>
