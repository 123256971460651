<script setup lang="ts">
import type { PropType } from "vue";

defineProps({
  menu: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
});

const emit = defineEmits(["menu-click"]);

const menuClick = (event: MouseEvent, item: any) => {
  emit("menu-click", event, item);
};
</script>

<template>
  <NavBarItem
    v-for="(item, index) in menu"
    :key="index"
    :item="item"
    @menu-click="menuClick"
  />
</template>
