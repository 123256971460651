import { mdiMonitor, mdiTable, mdiAccountGroup, mdiCreditCard } from "@mdi/js";

export default [
  {
    to: "/dashboard",
    icon: mdiMonitor,
    label: "Dashboard",
  },
  {
    to: "/company",
    label: "Company",
    icon: mdiTable,
  },
  {
    to: "/users",
    label: "Users",
    icon: mdiAccountGroup,
  },
  {
    to: "/promo",
    label: "Promo",
    icon: mdiCreditCard,
  },
];
